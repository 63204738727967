Util.createCPObject('cpm.ecommerce.basket');

cpm.ecommerce.basket = {
    init: function(){
    	$('.remove-item').click(function(e){
            cpm.ecommerce.basket.removeFromCart.call(this, e);
        })
    	$('#btnCheckout').click(function(e){
    		cpm.ecommerce.basket.checkout.call(this, e);
    	})

        $("input[name=qty]").livequery('change', function(e){
            cpm.ecommerce.basket.updateQuantity.call(this, e);
        });

        $("input[name=address_id]").livequery('click', function(e){
            var chosen = $(this).val();

            if (chosen == 'new'){
                $('#frmNewShippingAddress').removeClass('d-none', 1000);
            } else {
                $('#frmNewShippingAddress').addClass('d-none', 1000);
            }
        });
    },

    removeFromCart: function(e){
        e.preventDefault();
        Util.showProgressInd();
        var basketId = $(this).attr('basketId');
        var url = '/index.php?module=ecommerce_basket&_spAction=removeFromCart';
        $.get(url, {basketId: basketId}, function(json){
            document.location = document.location;
        });
    },

    updateQuantity: function(e){
        e.preventDefault();
        var rowObj = $(this).closest('.row');
        var unitPrice = $('.unitPrice', rowObj).text();
        var unitPrice = unitPrice.replace(/,/g, '');
        var basket_id = $(this).data('basket_id');

        var qty = $(this).val();
        var url = '/index.php?module=ecommerce_basket&_spAction=addToCart&showHTML=0';

        // Util.showProgressInd();
        $.getJSON(url, {basket_id: basket_id, qty: qty}, function(json){
            if(qty == 0){
                location.reload();
            }
            Util.hideProgressInd();
        });

        // update the total text
        $('.subTotal', rowObj).text(Util.addCommasToNumber(unitPrice * qty));

        grossTotal = 0;

        $(".basket .subTotal").each(function() {
            var value = $(this).text().replace(/,/g, '');
            // add only if the value is number
            if(!isNaN(value) && value.length != 0) {
                grossTotal += parseFloat(value);
            }
        });

        $('.basket .grossTotal').text(Util.addCommasToNumber(grossTotal));

        var shipping = $('.basket .shipping').text().replace(/,/g, '');
        var grandTotal = grossTotal + parseFloat(shipping);
        $('.basket .grandTotal').html(Util.addCommasToNumber(grandTotal));
    },

    checkout: function(e){
        e.preventDefault();
        Util.showProgressInd();
        if (cpt.generic.isLoggedIn(false)){
        } else {
            Util.hideProgressInd();
            $('#loginRegisterWrapper').slideDown();
            $('html,body').animate({scrollTop: $("#loginRegisterWrapper").offset().top - 100},'slow');
        }
    }
}
