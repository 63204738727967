Util.createCPObject('cpm.ecommerce.product');

cpm.ecommerce.product = {
    init: function(){
        $('#searchTop select#fld_category_id').on('change', function(){
            Util.loadDropdownByJSON('category_id', $(this).val(), 'fld_sub_category_id', 'webBasic_subCategory', 'searchTop');
        });

        $('.btnAddToWishlist').livequery('click', function(e){ //from product pages
            if (cpt.generic.isLoggedIn(false)){
                cpm.ecommerce.product.addToWishlist.call(this, e);
            } else {
                var msg = "\
                <div>\
                    You need to login to add items to the wishlist<br><br>\
                    <a href='/eng/login/'>LOGIN NOW!</a>\
                </div>\
                ";
                Util.alert(msg);
            }
        });

        $('.btnAddToCart').livequery('click', function(e){ //from product pages
            cpm.ecommerce.product.addToCart.call(this, e);
        });
    },

    addToCart: function(e){
        e.preventDefault();
        Util.showProgressInd();
        var itemId = $(this).attr('itemId');
        var url = '/index.php?module=ecommerce_basket&_spAction=addToCart';
        var btnObj = $(this)

        $.getJSON(url, {itemId: itemId}, function(json){
            Util.hideProgressInd();
            btnObj.attr('href', json.basketUrl);
            btnObj.removeClass('btn-dark btnAddToCart').addClass('btn-success');

            var html = "<i class='fas fa-cart-plus'></i> Added <i class='fas fa-check'></i>";

            btnObj.html(html);
        });
    },

    addToWishlist: function(e){
        e.preventDefault();
        Util.showProgressInd();
        var itemId = $(this).attr('itemId');
        var url = '/index.php?module=ecommerce_wishlist&_spAction=addToWishlist';
        var btnObj = $(this)

        $.getJSON(url, {itemId: itemId}, function(json){
            Util.hideProgressInd();
            btnObj.attr('href', json.myWishlistUrl);
            btnObj.removeClass('btn-dark btnAddToWishlist').addClass('btn-success');

            var html = "<i class='fas fa-heart'></i> Added <i class='fas fa-check'></i>";

            btnObj.html(html);
        });
    }
}