cpt.generic = {
    init: function(){
        $('.scrollup').click(function(e){
            e.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });

        cpt.generic.setBodyPanelHt();

        $(window).scroll(function(){
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });

        $('.imgAsBg').each(function () {
            var pic = $(this).attr('pic');
            var bgPosition = $(this).attr('bgPosition');

            $(this).css({
                'background-image': 'url("' + pic + '")'
            });

            if (bgPosition != '') {
                $(this).css({
                    'background-position': bgPosition
                });
            }
        });
    },
    setBodyPanelHt: function(){
        // alert($('footer').height());
        // 937-24-84-72
        var bodyHt = $(window).height() - $('.topBar').height() - $('.navbar').height() - $('footer').height() - 25;
        $('.bodyPanel').css('min-height', bodyHt + 'px' );
    },

    isLoggedIn: function(showAlert){
        $.ajax({
            type: 'GET',
            url: '/index.php?_spAction=isLoggedInWWW&showHTML=0',
            dataType: 'json',
            success: function(json) {
                res = json.status;
                if (json.status == 0 && showAlert){
                	Util.alert('Please login as a member before you proceed!');
                    Util.hideProgressInd();
                }
            },
            data: {},
            async: false
        });

        return res;
    }
}

const $navbar = $('#mainNav');

$('a.slide[href^="#"]').on('click', function(e) {
    e.preventDefault();
    const scrollTop = $($(this).attr('href')).position().top - $navbar.outerHeight();
    $('html, body').animate({ scrollTop }, 'slow');
})

$(function () {
    $('.sCarousel').slick({
        slidesToShow: 4,
        slidesToScroll: 4,
        dots: true,
        speed: 300,
        autoplay: true,
        easing: 'swing',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    })
    .on("afterChange", function (e, slick) {
        $('.sCarousel').slick("setOption", "autoplaySpeed", 5000);
    });
});


$(document).ready(function() {
	setTimeout(function(){
		$('body').addClass('loaded');
	}, 0);
});

$(function(){
    AOS.init();

    $('.tltBig').textillate({
        // in animation settings
        in: {
            delay: 20,
        },

        // set the type of token to animate (available types: 'char' and 'word')
        type: 'word'
    });

    $('.tltSmall').textillate({
        initialDelay: 200,
        // in animation settings
        in: {
            effect: 'fadeInRightBig',
            delay: 20
        },

        // set the type of token to animate (available types: 'char' and 'word')
        type: 'word'
    });

    $('.tltBannerTitle').textillate({
        initialDelay: 200,
        // in animation settings
        in: {
            effect: 'fadeInRightBig',
            delay: 20
        },

        // set the type of token to animate (available types: 'char' and 'word')
        type: 'word'
    });
});

$(document).ready(function () {
    var $menu = $('.navbar');
    var menuOffsetY = $menu.offset().top;

    function scroll() {
        if ($(window).scrollTop() >= menuOffsetY + 10) {
            $menu.addClass('scrolled');
        } else {
            $menu.removeClass('scrolled');
        }
    }

    document.onscroll = scroll;
});

$(document).ready(function() {
    // Open external links in a new window or tab
    $(document).on('click', 'a[rel$="external"]', function() {
      $(this).attr('target', "_blank");
    });
    $(document).on('click', 'a[href$=".pdf"]', function() {
      $(this).attr('target', "_blank");
    });

	$('a').each(function() {
		var a = new RegExp('/' + window.location.host + '/');
        var href = this.href;

		if(!a.test(href) && href.substr(0, 10) != 'javascript') {
			$(this).click(function(event) {
				event.preventDefault();
				event.stopPropagation();
                window.open(this.href, '_blank');
			}
			);
		}
	});

    // Javascript to enable link to tab
    var url = document.location.toString();
    if (url.match('#')) {
        var hash = url.split('#')[1];

        $('.nav-tabs a[href="#' + hash + '"]').tab('show');

        // Change hash for page-reload
        $('.nav-tabs a').on('shown.bs.tab', function (e) {
            window.location.hash = e.target.hash;
        })

        if ($("button[data-target='#" + hash + "']").length > 0){
            $("button[data-target='#" + hash + "']").trigger('click');
            setTimeout(function(){
                $('html,body').animate({
                    scrollTop: $(window).scrollTop() - 200
                });
            }, 1000);
        }
    }
});
